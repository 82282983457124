import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import CondoInfoPresentational from './CondoInfoPresentational';
import axios from 'axios';

const CondoInfo = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const { id, data: condo } = props;
  const isUpdating = id !== 'create';

  const updateCondo = async (data, id) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/condos/${id}`,
      data,
      {
        headers: { Authorization: token },
      }
    );
    hideLoading();

    if (response instanceof Error) {
      const message = JSON.parse(response?.request?.response).message;
      return alert.error(message || t('COMMON:GENERIC_ERROR'));
    }

    condo.vatCode = data.vatCode;
    alert.success(t('COMMON:SUCCESS_UPDATE'));
  };

  const checkVatCode = async (vatCode) => {
    showLoading();

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/condos/checkVatCode`,
      {
        vatCode: vatCode,
      },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      throw new Error('Errore nel controllo della partita IVA');
    }

    return response.data;
  };

  return (
    <CondoInfoPresentational
      isUpdating={isUpdating}
      defaultValues={condo}
      checkVatCode={checkVatCode}
      onConfirm={updateCondo}
      {...props}
    />
  );
};

export default CondoInfo;
