export const ADMIN_AFFILIATION_NET = 1000;
export const SUPPLIER_AFFILIATION_NET = 500;
export const AFFILIATION_TAX = 22;

export const AdminTypes = [
  'BUILDING_ADMIN_1',
  'BUILDING_ADMIN_2',
  'BUILDING_ADMIN_3',
  'BUILDING_ADMIN_4',
  'BUILDING_ADMIN_5',
];

export const SupplierTypes = ['SUPPLIER'];
export const AgencyTypes = ['AGENCY'];

export const ProfileTypes = [
  ...AdminTypes,
  ...SupplierTypes,
  'SUPPLIER_INS',
  'COMMERCIAL',
  'AGENCY',
  'GENERIC_CUSTOMER',
  'EON_ENERGY',
];

export const ProfileStatus = ['ACTIVATED', 'NOT_ACTIVATED'];

export const LAST_MASTER_YEAR = 2022;

export const ACCOUNTABILITY_LIMIT_DATE = '2023-04-01';
export const NUMBER_PAST_YEARS = 5;

export const IAFType = {
  INVOICES: 'INVOICES',
  INSURANCES: 'INSURANCES',
  INVOICES_SUB_ADMINS: 'INVOICES_SUB_ADMINS',
  ENERGY: 'ENERGY',
  RECURRENT_ENERGY: 'RECURRENT_ENERGY',
};

export const EON_PRODUCT_TYPE_LABELS = {
  EON_GAS_CERTO_NEW: 'E.ON GasCerto New',
  EON_GAS_CERTO_PREMIUM_NEW: 'E.ON Gas Certo Premium new',
  EON_DINAMICA_SEMPLICE_PIU: 'E.ON Dinamica semplice più',
  EON_LUCE_MERCATO_EASY: 'E.ON Luce Mercato Easy',
  EON_LUCE_MERCATO_PLUS_EASY: 'E.ON Luce Mercato Plus Easy',
  EON_LUCE_MERCATO_PLUS_PREMIUM: 'E.ON Luce Mercato Plus Premium',
  DUFERCO_GAS_VENERE: 'Duferco Gas Venere',
  DUFERCO_GAS_GIOVE: 'Duferco Gas Giove',
  DUFERCO_GAS_NETTUNO: 'Duferco Gas Nettuno',
  DUFERCO_LUCE_VENERE: 'Duferco Luce Venere',
  DUFERCO_LUCE_SATURNO: 'Duferco Luce Saturno',
  DUFERCO_LUCE_NETTUNO: 'Duferco Luce Nettuno',
};
